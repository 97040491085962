
export const environment = {
    production: true,
    //apiUrl: 'https://prodconvamiaafcmeapi.azurewebsites.net/ConvAmiAAFCME/',
    apiUrl: 'https://prodmamfapi.azurewebsites.net/ConvAmiMAMF/',
    apiReferentiel: 'https://prodconvamireferentielapi.azurewebsites.net/api/',
    notif: 'https://prodconvamireferentielapi.azurewebsites.net/',
    bookingUrl: 'https://prodbookingaafcmeweb.azurewebsites.net',
    encryptKey: 1203199320052021,
    encryptKeyIV: 1203199320052021
};
