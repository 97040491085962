<div class="layout-container" [ngClass]="containerClass">
    <div class="layout-content-wrapper">
        <app-topbar></app-topbar>
        <app-breadcrumb
            *ngIf="userAd"
            class="content-breadcrumb hidden"
        ></app-breadcrumb>
        <div class="layout-content">
            <router-outlet *ngIf="userAd; else contentLoading"></router-outlet>
            <ng-template #contentLoading>
                <div class="layout-preloader-container">
                    <div class="layout-preloader">
                        <span></span>
                    </div>
                </div>
            </ng-template>
        </div>

        <div class="layout-mask"></div>
    </div>
    <app-profilemenu *ngIf="userAd"></app-profilemenu>
    <app-Notifications-sidebar *ngIf="userAd"></app-Notifications-sidebar>
</div>
