import { Input, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { UserAdService } from '../main/service/user-ad.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: MenuItem[] = [];
    check: boolean = false;
    _aafcmeServices: MenuItem[] = [];
    _aafcmeConventions: MenuItem[] = [];

    finalMenuItemsCollections: MenuItem[] = [];

    isPresident: boolean = false;
    hasAccesToSuppliers: boolean = false;
    hasAccesToDataInjection: boolean = false;
    hasAccesSolfaBudget: boolean = false;
    hasAccesToStockOfficiel: boolean = false;
    @Input() userConnected: any;
    connectedUser: any;
    Nch_account: string = "Super Admin";
    PRESIDENT: string = "President";
    BACK_OFFICE: string = "Back-Office";


    constructor(private _userAd: UserAdService) {
        this._userAd.connectedUserAD$
            .subscribe({
                next: (_connectedUser: any) => {
                    if (_connectedUser) {
                        this.connectedUser = _connectedUser;
                    }
                }
            });
    }

    redirectToSignIn() {
        window.open(environment.bookingUrl, '_blank');
    }

    ngOnInit() {
        let userGroupsStringCollections: string[] = [];
        if (this.userConnected) {
            this.userConnected.userGroups.map(
                (_: any) => {
                    if (_) {
                        userGroupsStringCollections.unshift(_.displayName);
                    }
                }
            );
            if (userGroupsStringCollections.includes('Regional-Board') || userGroupsStringCollections.includes(this.BACK_OFFICE)) { this.hasAccesToSuppliers = false; } else { this.hasAccesToSuppliers = true; }
            if (userGroupsStringCollections.includes('Super Admin') || userGroupsStringCollections.includes('NCH-TECH')) { this.hasAccesToDataInjection = true; } else { this.hasAccesToDataInjection = false; }
            if (userGroupsStringCollections.includes('Regional-Board') || userGroupsStringCollections.includes('Back-Office')) { this.hasAccesToSuppliers = false; } else { this.hasAccesToSuppliers = true; }
            if (userGroupsStringCollections.includes('Super Admin')) { this.hasAccesToStockOfficiel = true; } else { this.hasAccesToStockOfficiel = false; }

            if (userGroupsStringCollections.includes('Regional-Board') || userGroupsStringCollections.includes('Back-Office')) {
                this.hasAccesSolfaBudget = false;
            }
            else { this.hasAccesSolfaBudget = true; }
        }

        this.model = [
            {
                label: "Vue 360°",
                fragment: 'assets/demo/images/metaverse.png',
                items: [
                    {
                        label: 'Fiche Adhérent',
                        fragment: 'assets/demo/images/details.png',
                        routerLink: '/app-layout/M-global-view/search-employee'
                    }
                    ,
                    {
                        label: 'Consultation des demandes',
                        fragment: 'assets/demo/images/suivi.png',
                        items: [
                            {
                                label: 'Suivi demande màj',
                                icon: 'pi pi-fw pi-th-large',
                                routerLink: '/app-layout/M-global-view/summary-requests/requests-update'
                            },
                            {
                                label: 'Reporting adhésion',
                                icon: 'pi pi-fw pi-verified',
                                routerLink: ['/app-layout/M-global-view/summary-requests/reporting-requests']
                            }
                        ]
                    }
                ]
            }
            ,
            {
                // label: 'Services',
                // fragment: 'assets/demo/images/share.png',
                // expanded: false,
                // items: [
                    // {
                    //     label: 'Service Solfa',
                    //     fragment: 'assets/demo/images/s.png',
                    //     items: [
                    //         {
                    //             label: 'Nouvelle Demande',
                    //             fragment: 'assets/demo/images/new-solfa.png',
                    //             routerLink: ['/app-layout/M-Aafcme-services/solfa-services/main-request']
                    //         },
                    //         {
                    //             label: 'Suivi demandes',
                    //             fragment: 'assets/demo/images/suivi.png',
                    //             routerLink: ['/app-layout/M-Aafcme-services/solfa-services/solfa-requests'],
                    //         },
                    //         {
                    //             label: 'Reporting',
                    //             fragment: 'assets/demo/images/reporting.png',
                    //             routerLink: ['/app-layout/M-Aafcme-services/solfa-services/solfa-reporting'],
                    //         },
                    //         {
                    //             label: 'Suivi Budget',
                    //             fragment: 'assets/demo/images/suivi-budget.png',
                    //             visible: this.hasAccesSolfaBudget,
                    //             items: [
                    //                 {
                    //                     label: 'Ajout budget initial',
                    //                     visible: this.hasAccesSolfaBudget,
                    //                     fragment: 'assets/demo/images/AddBudget.png',
                    //                     routerLink: ['/app-layout/M-Aafcme-services/sfs/budget-monitoring/add-budget']
                    //                 },
                    //                 {
                    //                     label: ' Suivi Consommation',
                    //                     fragment: 'assets/demo/images/SuiviConsommation.png',
                    //                     visible: this.hasAccesSolfaBudget,
                    //                     routerLink: ['/app-layout/M-Aafcme-services/sfs/budget-monitoring/consumption-monitoring'],
                    //                 }

                    //             ]
                    //         }
                    //     ]
                    // },
                    // {
                    //     label: 'Solfa Allouche',
                    //     fragment: 'assets/demo/images/sa.png',
                    //     items: [
                    //         {
                    //             label: 'Nouvelle Demande',
                    //             fragment: 'assets/demo/images/new-solfa.png',
                    //             routerLink: ['/app-layout/M-Aafcme-services/sa-services/main-request-sa/search-employee-sa']
                    //         },
                    //         {
                    //             label: 'Suivi demandes',
                    //             fragment: 'assets/demo/images/suivi.png',
                    //             routerLink: ['/app-layout/M-Aafcme-services/sa-services/sa-requests'],
                    //         },
                    //         {
                    //             label: 'Reporting',
                    //             fragment: 'assets/demo/images/reporting.png',
                    //             routerLink: ['/app-layout/M-Aafcme-services/sa-services/sa-reporting'],
                    //         }
                    //     ]
                    // },
                    // {
                        label: 'Resa Booking',
                        fragment: 'assets/demo/images/share.png',
                        items: [
                            // {
                            //     label: 'Nouvelle réservation',
                            //     fragment: 'assets/demo/images/reserver.png',
                            //     routerLink: ['/app-layout/M-Aafcme-services/resa-services/main-request-resa/search-employee-resa']
                            // },
                            {
                                label: 'Suivi demandes',
                                fragment: 'assets/demo/images/suivi.png',
                                routerLink: ['/app-layout/M-Aafcme-services/resa-services/resa-requests'],
                            },
                            {
                                label: 'Reporting',
                                fragment: 'assets/demo/images/reporting.png',
                                routerLink: ['/app-layout/M-Aafcme-services/resa-services/resa-reporting'],
                            },
                            // {
                            //     label: 'Reporting Résidence',
                            //     fragment: 'assets/demo/images/reporting.png',
                            //     routerLink: ['/app-layout/M-Aafcme-services/resa-services/resa-reporting-appartment'],
                            // },
                            // {
                            //     label: 'Calendrier Résidence',
                            //     fragment: 'assets/demo/images/calandar.png',
                            //     routerLink: ['/app-layout/M-Aafcme-services/resa-services/resa-calendar'],
                            // }
                        ]
                   // }
              //  ]
            },
            {
                label: "Prime Rentrée",
                fragment: 'assets/demo/images/help.png',
                items: [
                    {
                        label: 'تحيين معطيات المنخرط',
                        fragment: 'assets/demo/images/9977091.png',
                        routerLink: '/app-layout/M-global-view/search-employee'
                    }
                    ,
                    {
                        label: 'تحيين معطيات عائلة المنخرط',
                        fragment: 'assets/demo/images/9795194.png',
                        routerLink: '/app-layout/M-global-view/search-employee'
                    }                    ,
                    {
                        label: 'إيداع مطلب منحة العودة المدرسية',
                        fragment: 'assets/demo/images/suivi.png',
                        routerLink: '/app-layout/M-global-view/search-employee'
                    }                    ,
                    {
                        label: 'Suivi et traitement des demandes',
                        fragment: 'assets/demo/images/purchase-order-officiel.png',
                        routerLink: '/app-layout/M-global-view/search-employee'
                    },
                    {
                        label: 'Reporting',
                        fragment: 'assets/demo/images/reporting.png',
                        routerLink: '/app-layout/M-global-view/search-employee'
                    }
                ]
            }
            // {
            //     label: "Fournisseurs",
            //     visible: this.hasAccesToSuppliers,
            //     fragment: 'assets/demo/images/pipeline.png',
            //     items: [
            //         {
            //             label: 'Ooredoo',
            //             fragment: 'assets/demo/images/ooredoo.png',
            //             items: [
            //                 {
            //                     label: 'Suivi dérogations',
            //                     fragment: 'assets/demo/images/derog.png',
            //                     routerLink: ['/app-layout/M-suppliers/ooredoo-area/exceptionnal-purchases']
            //                 },
            //                 {
            //                     label: 'Suivi demandes',
            //                     fragment: 'assets/demo/images/suivi.png',
            //                     routerLink: ['/app-layout/M-suppliers/ooredoo-area/requests']
            //                 },
            //                 {
            //                     label: 'Reporting',
            //                     fragment: 'assets/demo/images/reporting.png',
            //                     routerLink: ['/app-layout/M-suppliers/ooredoo-area/reporting']
            //                 }
            //             ]
            //         },
            //         {
            //             label: 'Autres fournisseurs',
            //             fragment: 'assets/demo/images/other.png',
            //             items: [
            //                 {
            //                     label: 'Suivi demandes',
            //                     fragment: 'assets/demo/images/suivi.png',
            //                     routerLink: ['/app-layout/M-suppliers/extra-suppliers-area/requests']
            //                 },
            //                 {
            //                     label: 'Reporting',
            //                     fragment: 'assets/demo/images/reporting.png',
            //                     routerLink: ['/app-layout/M-suppliers/extra-suppliers-area/reporting']
            //                 }

            //             ]
            //         }

            //     ]
            // }
        ];
    }
}
