import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class NotifierDataService implements OnDestroy {

    private _notifs: any = null;
    private notifs = new BehaviorSubject<any>(this._notifs);
    notifs$ = this.notifs.asObservable();

    private _unsubscribeAll: Subject<any>;

    constructor(private _http: HttpClient
    ) {
        this._unsubscribeAll = new Subject();
    }

    Notification() {

        // return this._http.get(`${environment.apiUrl}Notification/GetAAFCMEUserNotifications`,
        // );

    }

    RefreshNotifications(notif: any) {
        this._notifs = notif;
        this.notifs.next(notif);
    }

    ngOnDestroy(): void {
        // Emit something to stop all Observables
        this._unsubscribeAll.next(null);
        // Complete the notifying Observable to remove it
        this._unsubscribeAll.complete();
    }

}
