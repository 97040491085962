<div
    class="layout-breadcrumb flex align-items-center relative prevent-select h-3rem"
>
    <nav>
        <ol class="relative px-3 z-2">
            <ng-template
                ngFor
                let-item
                let-last="last"
                let-first="first"
                [ngForOf]="breadcrumbs$ | async"
            >
                <div
                    class="hover:surface-hover cursor-pointer p-2 transition-duration-150 border-round"
                    *ngIf="item?.fragment"
                >
                    <li class="flex align-items-center gap-2">
                        <img
                            *ngIf="item?.fragment"
                            [src]="item?.fragment"
                            width="25"
                        />
                        <span
                            *ngIf="!first"
                            [ngClass]="{ 'text-primary font-medium ': last }"
                            class="text-700 font-normal text-lg"
                            style="font-style: oblique"
                            >{{ item.label }}</span
                        >
                        <span
                            *ngIf="first"
                            style="font-style: oblique"
                            class="text-700 text-lg font-normal cursor-pointer transition-duration-150 border-round"
                            >{{ item.label }}</span
                        >
                    </li>
                </div>

                <div *ngIf="!item.fragment">
                    <li
                        [ngClass]="{
                            'text-primary font-medium ': last
                        }"
                        class="text-700 font-medium text-lg hover:surface-hover cursor-pointer p-2 transition-duration-150 border-round"
                        style="font-style: oblique"
                    >
                        {{ item.label }}
                    </li>
                </div>

                <li *ngIf="!last" class="layout-breadcrumb-chevron pt-1">
                    <i
                        class="text-primary pi pi-angle-right"
                        style="font-size: large"
                    ></i>
                </li>
            </ng-template>
        </ol>
    </nav>
</div>
