import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserAdService implements OnDestroy {

    private _connectedUserAD: any = null;
    private connectedUserAD = new BehaviorSubject<any>(this._connectedUserAD);
    connectedUserAD$ = this.connectedUserAD.asObservable();

    private _isStore: any = null;
    private isStore = new BehaviorSubject<any>(this._isStore);
    isStore$ = this.isStore.asObservable();

    private _unsubscribeAll: Subject<any>;

    get Headers() {
        return { 'content-type': 'application/json' };
    }

    setUser(subjectParam: any) {
        this._connectedUserAD = subjectParam;
        this.connectedUserAD.next(subjectParam);
    }

    removeUser() {
        this._connectedUserAD = null;
        this.connectedUserAD.next(null);
    }

    IsStore(subject: boolean) {
        this._isStore = subject;
        this.isStore.next(subject);
    }

    constructor(
        private http: HttpClient
    ) {
        this._unsubscribeAll = new Subject();
    }

    UserLoggedIn() {
        return this.http.get(`${environment.apiUrl}User/GetCurrentUser`,
            {
                headers: this.Headers
            }
        );
    }

    ngOnDestroy(): void {
        // Emit something to stop all Observables
        this._unsubscribeAll.next(null);
        // Complete the notifying Observable to remove it
        this._unsubscribeAll.complete();
    }
}
