
import { BrowserCacheLocation, Configuration, LogLevel } from "@azure/msal-browser";

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1
    ||
    window.navigator.userAgent.indexOf("Trident/") > -1;

export const auth_sso_configuration: Configuration = {
    auth: {
        clientId: '6cf261b4-b6cc-4b71-b620-09b98254c3e8',
        authority: 'https://login.microsoftonline.com/20923150-f613-4b38-af27-a5dd1cb27af1',
        redirectUri: '/signin-oidc',
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE,
        secureCookies: true
    },
    system: {
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
                // console.log(logLevel, message);
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false
        }
    }
}

export const AUTH_SSO_MSAL = {
    protectedResourceMap: new Map([
        ['https://prodmamfapi.azurewebsites.net/*', ['api://d564e6df-e47f-4de0-92ce-2dff5509011e/Mamf']],
        ['https://intmamfapi.azurewebsites.net/*', ['api://d564e6df-e47f-4de0-92ce-2dff5509011e/Mamf']],
        ['https://devmamfapi.azurewebsites.net/*', ['api://d564e6df-e47f-4de0-92ce-2dff5509011e/Mamf']],
        ['http://localhost:4200/*', ['api://d564e6df-e47f-4de0-92ce-2dff5509011e/Mamf']]
    ])
}

