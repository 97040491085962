import { Subject, Subscription, catchError, takeUntil, throwError } from 'rxjs';
import { Component, OnDestroy, OnInit } from "@angular/core";
import { LayoutService } from "./service/app.layout.service";
import { UserAdService } from '../main/service/user-ad.service';
import { NotifierDataService } from './signal-r/notifier-data.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-Notifications-sidebar',
    templateUrl: './app.notifications-sidebar.component.html'
})
export class AppNotificationsSidebarComponent implements OnInit, OnDestroy {

    _notifications: any[] = [];

    userAD: any;
    hasAaccess: boolean = false;
    loading: boolean = false;

    private subscription: Subscription;
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _router: Router,
        private _userAD: UserAdService,
        private _notification: NotifierDataService,
        public layoutService: LayoutService) {
        this._unsubscribeAll = new Subject();
        this._userAD.connectedUserAD$.subscribe(
            {
                next: (value) => {
                    this.userAD = value;
                }
            }
        );
        this._userAD.connectedUserAD$
            .subscribe(
                {
                    next: (_user) => {
                        let groups: string[] = [];
                        _user.userGroups.map(
                            (group: any) => {
                                groups.push(group.displayName);
                            }
                        );
                        if (groups.includes('Executive-Board') && groups.includes('President')) {
                            this.hasAaccess = true;
                            this.Notifications();
                        } else {
                            this.hasAaccess = false;
                        }
                    }
                }
            );
    }

    getShortName(name: string) {
        return name.split(' ').map(n => n[0]).join('');
    }

    ngOnInit(): void {
        this.subscription = this._notification.notifs$
            .subscribe(
                {
                    next: (_changesNotifs) => {
                        if (_changesNotifs)
                            this._notifications = _changesNotifs;
                    }
                }
            );
    }

    Notifications() {
        // this.loading = true;
        // this.subscription = this._notification.Notification()
        //     .pipe(
        //         takeUntil(this._unsubscribeAll),
        //         catchError(err => {
        //             return throwError(() => err);
        //         })
        //     )
        //     .subscribe(
        //         {
        //             next: (response: any) => {
        //                 this._notifications = response.data;
        //                 this._notification.RefreshNotifications(this._notifications);
        //             },
        //             complete: () => {
        //                 this.loading = false;
        //             }
        //         }
        //     );
    }

    navigate(notification: any) {
        this._router.navigateByUrl("/app-layout/M-suppliers/ooredoo-area/exceptionnal-purchases/" + notification?.requestId);
        setTimeout(() => {
            this.visible = false;
        }, 500);
    }

    get visible(): boolean {
        return this.layoutService.state.notificationSidebar;
    }

    set visible(_val: boolean) {
        this.layoutService.state.notificationSidebar = _val;
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
        // Emit something to stop all Observables
        this._unsubscribeAll.next(null);
        // Complete the notifying Observable to remove it
        this._unsubscribeAll.complete();
    }

}
