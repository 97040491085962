<p-sidebar
    [(visible)]="visible"
    position="right"
    [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'"
    styleClass="layout-profile-sidebar w-full sm:w-28rem"
    [showCloseIcon]="false"
>
    <div
        class="layout-rightmenu overflow-x-hidden"
        [ngClass]="{
            'layout-rightmenu-active': layoutService.state.notificationSidebar
        }"
    >
        <div
            class="flex justify-content-center align-items-center gap-2 prevent-select"
        >
            <img src="assets/demo/images/notifications.png" width="35" />
            <h4 class="text-700">Centre des notifications</h4>
        </div>
        <div class="col-12">
            <div class="card p-0">
                <div
                    class="timeline-header p-3 flex justify-content-between align-items-center"
                >
                    <p class="m-0 font-medium text-primary prevent-select">
                        Historique des notifications
                    </p>
                    <div class="header-icons">
                        <p-button
                            icon="pi pi-refresh"
                            styleClass="p-button-rounded p-button-text"
                            (onClick)="Notifications()"
                        ></p-button>
                    </div>
                </div>
                <div style="overflow-y: scroll; height: 45.5rem">
                    <ul
                        *ngIf="!loading"
                        class="mt-2 flex flex-row md:flex-column w-full gap-1 md:gap-2 list-none m-0 p-0 overflow-auto"
                    >
                        <li
                            pRipple
                            *ngFor="
                                let notification of _notifications;
                                let i = index;
                                let first = first
                            "
                            class="bg-notif hover:surface-hover cursor-pointer select-none p-3 w-full transition-duration-150 border-round flex align-items-center justify-content-center md:justify-content-start md:flex-1 flex-auto"
                            (click)="navigate(notification)"
                        >
                            <img
                                class="col-1 p-0"
                                src="assets/demo/images/warning.png"
                                width="22"
                            />
                            <div class="col-11 p-0" style="display: grid">
                                <span
                                    class="text-normal text-800 ml-2 font-medium hidden md:inline"
                                    style="font-style: oblique"
                                    >{{
                                        notification.contextOutputMessage
                                    }}</span
                                >
                                <span class="text-600 ml-2">
                                    {{
                                        notification.operationDate
                                            | date : "dd/MM/YYYY, H:mm"
                                    }}
                                </span>
                            </div>
                        </li>
                    </ul>
                    <ul
                        *ngIf="loading"
                        class="mt-2 flex flex-row md:flex-column w-full gap-1 md:gap-2 list-none m-0 p-0 overflow-auto"
                    >
                        <li
                            pRipple
                            *ngFor="let _ of [].constructor(6)"
                            class="flex align-items-center justify-content-center md:justify-content-start md:flex-1 flex-auto"
                            (click)="navigate(item)"
                        >
                            <p-skeleton
                                styleClass="hover:surface-hover cursor-pointer select-none p-3  transition-duration-150 border-round"
                                width="326.32px"
                                height="96.01px"
                            ></p-skeleton>
                        </li>
                    </ul>
                    <ul *ngIf="!loading && _notifications.length === 0">
                        <div class="mt-4 pt-5 py-5 flex justify-content-center prevent-select">
                            <img
                                src="assets/demo/images/empty-notif.png"
                                width="80"
                            />
                        </div>
                        <div
                            class="flex justify-content-center mt-2 text-400 text-normal prevent-select"
                        >
                            Aucune notification n'a été trouvé
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</p-sidebar>
