<div
    [@slideInOut]="{
        value: '',
        params: { direction: '-100' }
    }"
    class="layout-sidebar"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
>
    <div class="sidebar-header">
        <div>
            <a [routerLink]="['/']" class="app-logo">
                <div class="app-logo-small h-2rem">
                    <img
                        *ngIf="userAD; else loadingS"
                        src="assets/demo/images/convami.png"
                    />

                    <div class="app-logo-small h-2rem">
                        <ng-template #loadingS>
                            <div class="boxes">
                                <div class="box">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <div class="box">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <div class="box">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <div class="box">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
                <div class="app-logo-normal">
                    <img
                        width="100"
                        *ngIf="userAD; else loadingN"
                        class="h-8rem"
                        style="margin-left: 4rem"
                        src="assets/demo/images/convami.png"
                    />
                    <ng-template #loadingN>
                        <div class="boxes">
                            <div class="box">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div class="box">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div class="box">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div class="box">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </a>
            <button
                class="layout-sidebar-anchor p-link z-2"
                type="button"
                (click)="anchor()"
            ></button>
        </div>
    </div>

    <div #menuContainer class="layout-menu-container">
        <app-menu
            [userConnected]="userAD"
            *ngIf="userAD; else loadingMenuContainer"
        ></app-menu>
        <ng-template #loadingMenuContainer>
            <ul class="layout-menu" style="margin-top: 1.5rem">
                <ng-container
                    *ngFor="let fake of [].constructor(6); let i = index"
                >
                    <li>
                        <p-skeleton
                            size="4rem"
                            styleClass="ml-3 mb-5"
                        ></p-skeleton>
                    </li>
                </ng-container>
            </ul>
        </ng-template>
    </div>
</div>
